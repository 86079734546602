.Footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F7F7F7;
    text-align: center;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1em var(--main-padding);
    color: rgba(96, 95, 90, .55);
    font-size: .75em;

    border: var(--test-border-dotted);
    border-color: green;
}

.link {
    color: rgba(96, 95, 90, .85);
    text-decoration: none;
    padding-left: 2em;
}

.link:hover {
    text-decoration: underline;
}

.links {
    padding-top: .5em;
    font-size: .85em;

    border: var(--test-border-dotted);
    border-color: red;
}

.copy {
    font-size: .85em; 
    border: var(--test-border-dotted);
    border-color: red;
}

@media (min-width: 390px) {
    .copy {
        font-size: 1em;
    }
}

@media (min-width: 765px) {
    .content {
        flex-direction: row;
        justify-content: space-between;
        max-width: var(--max-page-width);
        min-width: var(--max-page-width);
        margin: 0 auto;
        font-size: .85em;
    }   

    .links {
        display: flex;
        flex-direction: row;
        padding-top: 0;
        font-size: 1em;

        border: var(--test-border-dotted);
        border-color: red;
    }
}

@media (max-width: 424px) {
    .links {
        display: flex;
        flex-direction: column;
        align-items: center; 
        padding-left: 0;
    }

    .link {
        padding-left: 0; 
        padding-bottom: 0.5em; 
    }

    .link:last-child {
        padding-bottom: 0; 
    }
}
