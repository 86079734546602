.container {
}

.aboutInfo {
    margin-top: 0.75em;
    margin-bottom: 0.5em;
}

.sectionTitle {
  font-size: 0.875em;
  margin-top: 0.75em;
  margin-bottom: 0.5em;
}

.paragraph {
  font-size: 0.75em;
  margin-bottom: 0.5em;
  line-height: 1.4em;
  font-weight: 300;
}

.list {
  list-style-type: decimal;
  padding-left: 1.5em;
}

.listItem {
  font-size: 0.75em;
  margin-bottom: 0.5em;
  line-height: 1.4em;
  font-weight: 300;
}

.bold {
  font-weight: bold;
}

.contactInfo {
  font-size: 0.75em;
  margin-bottom: 0.5em;
  line-height: 1.4em;
}

.contactLink {
  color: #007bff;
  text-decoration: none;
}

.contactLink:hover {
  text-decoration: underline;
}

@media (min-width: 390px) {
  .pageTitle {
    font-size: 1.2em;
  }

  .sectionTitle {
    font-size: 1.2em;
  }

  .paragraph {
    font-size: 0.875em;
  }

  .listItem {
    font-size: 0.875em;
  }

  .contactInfo {
    font-size: 0.875em;
  }
}

@media (min-width: 765px) {
  .pageTitle {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  .sectionTitle {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .paragraph {
    font-size: 1em;
    margin-bottom: 0.5em;
    line-height: 1.4em;
  }

  .contactInfo {
    font-size: 1em;
    margin-bottom: 0.5em;
  }
}
