.PaymentButton {
    display: flex;
    align-items: center;
    
    width: 100%;

    padding: .75em; 
    /* margin: calc(2.5 * var(--main-padding)) 0; */

    background: rgba(96, 95, 90, 1);
    opacity: .65;

    border: 0;
    border-radius: 10px;
    box-shadow: none;
    cursor: not-allowed;

    transition: all 0.2s ease; 
}

.icon {
    margin-right: calc(1.25 * var(--main-padding));
    opacity: .75;
    transition: all 0.2s ease;
}
.PaymentButton:hover > .icon {
    opacity: 1;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
}

.amount {
    font-size: 1.5em; 
    font-weight: 700;
}

.description {
    font-size: .9em; 
    font-weight: 300;
    text-align: left;
}


.PaymentButton[type="card"] {
    text-shadow: 1px 1px 5px rgba(67, 126, 134, .45);
    background:rgba(101, 195, 208, 1);
    cursor: pointer;
    opacity: 1;
    box-shadow: 0px 4px 10px 0px rgba(101, 195, 208, .55);
    border: 1px solid rgba(67, 126, 134, .15);
}

.PaymentButton[type="card"]:hover {
    box-shadow: 0px 4px 20px 0px rgba(101, 195, 208, .85);
}

.PaymentButton[type="card"]:active {
    background: rgba(86, 162, 173, 1);
    transform: translateY(2px);
    box-shadow: none;
}


.PaymentButton[type="sbp"] {
    text-shadow: 1px 1px 5px rgba(194, 126, 26, .65);
    background:rgba(255, 166, 34, 1);
    cursor: pointer;
    opacity: 1;
    box-shadow: 0px 4px 10px 0px rgba(255, 166, 34, .55);
    border: 1px solid rgba(194, 126, 26, .15);
}

.PaymentButton[type="sbp"]:hover {
    box-shadow: 0px 4px 20px 0px rgba(255, 166, 34, .85);
}

.PaymentButton[type="sbp"]:active {
    background: rgba(228, 149, 31, 1);
    transform: translateY(2px);
    box-shadow: none;
}

@media (min-width: 390px) {
    .PaymentButton {
        padding: calc(1.25 * var(--main-padding)) calc(1.5 * var(--main-padding));
        /* margin: calc(2.5 * var(--main-padding)) 0; */
    }

    .amount {
        font-size: 3.15em; 
        font-weight: 700;
    }
    

    .description {
        font-size: 1.15em;
    }
}
