.container {
    display: flex;
    flex-direction: column;
    /* gap: calc(1.5 * var(--main-padding)); */
    padding-top: .75em; 
}

.preloader {
    padding-top: .75em; 
}

.footer {
    display: block;
    padding-top: .75em; 
} 

.amount {
    font-size: 2em; 
    font-weight: 900;
    color: rgba(96, 95, 90, 1);
    margin: .15em 0;
    letter-spacing: -2px;
}

.localAmount {
    font-size: calc(var(--main-padding) * 1.5) !important;
    font-weight: 500;
    color: rgba(96, 95, 90, .65);
    letter-spacing: -2px;
}

.line {
    display: block;
   /*  height: var(--main-padding); */
    border-bottom: 1px dotted rgba(96, 95, 90, .85);
}

.status {
    font-size: 1em;
    font-weight: 500;
    color: rgba(96, 95, 90, .65);
    margin-top: .75em;
    letter-spacing: -.5px;
    margin: .15em 0; 
}

.status[type="600"] {
    color: rgba(133, 175, 106, .85);
}

.status[type="400"] {
    color: rgba(252, 86, 86, .85);
}

.help {
    display: flex;
    flex-direction: column;
    gap:  0.75em;
    margin-top: 1.5em; 
    /* padding-top: calc(1.5 * var(--main-padding)); */
}

.help h3 {
    font-size: 1em; 
    color: rgba(96, 95, 90, .75);
    font-weight: 500;
}

.item {
    font-size: .87em;
    color: rgba(96, 95, 90, .95);
    min-width: 100px;
    line-height: 1.1;
}

.item a {
    display: block;
    text-decoration: none;
    font-size: .75em;
    font-weight: bold;
    text-align: center;
    color: #fff;
    border: 0;
    border-radius: 10px;
    padding: 1em;
    margin-top: .85em;
    background-color: rgba(96, 95, 90, 1);
    max-width: 190px;
}

.item a[type="mail"] {
    background-color: rgba(228, 149, 31, 1);
    text-shadow: 1px 1px 5px rgba(194, 126, 26, .65);
}

.item a[type="tg"] {
    background-color: rgba(86, 162, 173, 1);
    text-shadow: 1px 1px 5px rgba(67, 126, 134, .45);
}

@media (min-width: 390px) {
    .container {
        padding-top: var(--main-padding);
    }
    
    .preloader {
        padding-top: calc(1.5 * var(--main-padding));
    }
    
    .footer {
        padding-top: calc(2 * var(--main-padding));
    }
    
    .amount {
        font-size: calc(var(--main-padding) * 3.5);
    }
    
    .localAmount {
        font-size: calc(var(--main-padding) * 1.5) !important;
        font-weight: 500;
        color: rgba(96, 95, 90, .65);
        letter-spacing: -2px;
    }
    
    .line {
        height: var(--main-padding);
    }
    
    .status {
        font-size: calc(var(--main-padding) * 1.5);
    }
    
    
    .help {
        margin-top: calc(3 * var(--main-padding));
    }
    
    .help h3 {
        font-size: 1.5em;
    }
}

@media (min-width: 765px) {
    .footer { 
        max-width: 300px; 
    }

    .help {
        flex-direction: row;
    }

    .item {
        min-width: 200px;
        font-size: .77em;
    }

    .item a {
        font-size: 1em;
    }    

    .help h3 {
        font-size: 1.55em;
        max-width: 170px; 
    }    
}
