.Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .75em; 

    border: var(--test-border-dotted);
    border-color: green;
}

@media (min-width: 390px) {
    .Content {
        padding: var(--main-padding);
    }
}

@media (min-width: 765px) {
    .Content {
    
    }
}
