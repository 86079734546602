.Logo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  background-image: url(./logo.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;

  border: var(--test-border-dotted);
  border-color: blue;
}

@media (min-width: 390px) {
  .Logo {
    height: 100px;
    background-position: top 0.5em center;
    background-size: auto;
  }
}

@media (min-width: 765px) {
  .Logo {
    background-position: var(--main-padding) center;
    height: 110px;
  }
}
